.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.load-bar {
	animation-name: backgroundColorPalette;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
	/* linear is enabled default, it’s not necessary to add it make it work but it can make your code more expressive */
}

@keyframes backgroundColorPalette {
	0% {
		background: linear-gradient(90deg, rgba(245,244,134,1) 0%, rgba(221,164,80,1) 100%);
	}
  12.5% {
    background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 12.5%, rgba(221,164,80,1) 100%);
  }
	25% {
    background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 25%, rgba(221,164,80,1) 100%);
	}
	37.5% {
		background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 37.5%, rgba(221,164,80,1) 100%);
	}
	50% {
		background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 50%, rgba(221,164,80,1) 100%);
	}
  62.5% {
    background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 62.5%, rgba(221,164,80,1) 100%);
  }
	75% {
		background: linear-gradient(90deg, rgba(221,164,80,1) 0%, rgba(245,244,134,1) 100%);
	}
  87.5% {
    background: linear-gradient(90deg, rgba(221,164,80,1) 50%, rgba(245,244,134,1) 100%);
  }
  100% {
    background: linear-gradient(90deg, rgba(245,244,134,1) 0%, rgba(221,164,80,1) 50%);;
  }
}

.no-spinner {
  -moz-appearance: textfield;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}