@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

input {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body#text {
  margin: 0;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, label, button.ui, h1, h2, h3, h4, h5, p, pre {
  font-family: 'Open Sans', sans-serif;
  color: #464646;
}

h1 {
  letter-spacing: -2px;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

h1.jumbo {
  font-weight: 700;
  font-size: 4rem;
  line-height: 4rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: -1px;
}

h3 {
  font-weight: 700;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
}

span.gradient {
  background: -webkit-linear-gradient(310deg, #a4e2d5, #08ae9c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
span.lightgradient {
  background: -webkit-linear-gradient(310deg, #F5F486, #DDB12D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blacktowhite {
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, #ffffff 50%, #ffffff 100%);
}

p{
  font-weight: 300;
  line-height: 1rem;
}

label {
  font-weight: 600;
}

.tight {
  letter-spacing: -1px;
}

small {
  line-height: 0.8rem;
}

a{
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

strong {
  font-weight: 700;
}

textarea {
  resize: both;
}

a:hover {
  text-decoration: none;
}

.array .row:first-child .remove-icon{
  padding-top: 1.5rem;
}

.array .row:not(:first-child) .input label{
  display: none;
}

.array .row:not(:first-child) .total label{
  display: none;
}

.array .row:not(:first-child) .line-item-label{
  display: none;
}

.array .survey-table .survey-table label {
  display: block !important;
}

.show-label label {
  display: block !important;
}

.survey-table-parent h2{
  font-weight: 700 !important;
}

.survey-table .survey-table-parent h2{
  font-weight: 500 !important;
}

.survey-table .total:not(:last-child):not(.line-item *) .total-input{
  display: none;
}

.row:not(:last-child) .total-input{
  display: none;
}

.line-item:not(:last-child) .total-input{
  display: none;
}

.hide-total .total-input{
  display: none;
}

/* .survey-table .line-item:not(:last-child) .total .total-input{
  display: none;
} */

.line-item div{
  flex-grow: 1;
}

.line-item input{
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
}

html {
  font-size: 16px;
}

hr {
  width: 100%;
}

th {
  font-weight: 600;
  font-size: 0.95rem;
}
.scroller-min {
  scrollbar-width: none;
}
/* width */
.scroller::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  /* border-left-color: #e5e7eb;
  border-left-style:solid; */
  border-left-width: 1px;
}
 
/* Handle */
.scroller::-webkit-scrollbar-thumb {
  /* background: red;  */
  background: #e5e7eb;
}

/* Handle on hover */
.scroller::-webkit-scrollbar-thumb:hover {
}

/* width */
.scroller-min::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.scroller-min::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px white; 
  border-left-width: 0px;
}
 
/* Handle */
.scroller-min::-webkit-scrollbar-thumb {
  /* background: red;  */
  background: white;
}

/* Handle on hover */
.scroller-min::-webkit-scrollbar-thumb:hover {
}
